import { useLocale } from '../locale';

export interface DateRangeProps {
  start: string;
  end?: string | null;
}

/**
 * @param start Start date string.
 * @param end End date string.
 */
const DateRange = ({ start, end }: DateRangeProps) => {
  const { formatDate } = useLocale();

  const startDate = new Date(start);
  const endDate = end ? new Date(end) : null;
  const sameDay = endDate && formatDate(startDate, 'display_date') === formatDate(endDate, 'display_date');

  if (sameDay) {
    return (
      <>
        {formatDate(startDate, 'display_date')}
      </>
    );
  }

  if (endDate) {
    return (
      <>
        {formatDate(startDate, 'display_date')}
        <span className="opacity-50"> &ndash; </span>
        {formatDate(endDate, 'display_date')}
      </>
    );
  }

  return <>{formatDate(startDate, 'display_date')}</>;
};

export default DateRange;

import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';

type RadioProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (props, ref) => {
    const className = classNames(
      'w-4 h-4 text-blue-600 border-2 border-slate-250 focus:ring-blue-400/50 focus:ring-2',
      props?.disabled && 'opacity-60 pointer-events-none',
      props.className,
    );

    return (
      <input type="radio" {...props} className={className} ref={ref} />
    );
  }
);

Radio.displayName = 'Radio';

import { RequestPayload } from '@inertiajs/core';
import { router } from '@inertiajs/react';
import classNames from 'classnames';
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDown, Filter, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import useFocusIndex from '../useFocusIndex';
import useOnClickOutside from '../useOnClickOutside';
import { Button } from './Button';
import { FadeIn } from './FadeIn';
import { Icon } from './Icon';
import { Input, InputProps } from './Input';
import { InputWrapper } from './InputWrapper';

export interface PickerItem {
  id: string;
  title: string;
}

interface PickerProps<T extends PickerItem> extends Omit<InputProps, 'onChange'> {
  name?: string;
  items: T[];
  onChange?: (item: T | null) => void;
  selected: string | null;
  optional?: boolean;
  icon?: ReactNode;
  payload?: (id: string | null) => RequestPayload;
}

export default function Picker<T extends PickerItem>({
  name,
  items,
  onChange,
  selected,
  optional = false,
  icon,
  placeholder,
  payload = () => ({ page: undefined }),
  className,
  disabled,
  ...props
}: PickerProps<T>) {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [filteredItems, setFilteredItems] = useState(items);
  const initialIndex = items.findIndex((item) => item.id === selected);
  const selectedItem: T | null = items[initialIndex] || null;

  const [search, setSearch] = useState(selectedItem?.title || '');
  const [active, setActive] = useState(false);

  const searchTimerRef = useRef<number>();

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    setActive(true);
    setSearch(search);

    window.clearTimeout(searchTimerRef.current);

    searchTimerRef.current = window.setTimeout(() => {
      const filteredItems = search ? items.filter((item) => item.title.toLowerCase().includes(search.toLowerCase())) : items;
      setFilteredItems(filteredItems);
      setFocusIndex(() => search && filteredItems.length > 0 ? 0 : initialIndex);
    }, 100);
  };

  useEffect(() => () => window.clearTimeout(searchTimerRef.current), []);

  const openPicker = useCallback(() => {
    setActive(true);
    setSearch('');
    setFilteredItems(items);
    setFocusIndex(items.findIndex(({ id }) => id === selectedItem?.id));
  }, [items, selectedItem]);

  const closePicker = useCallback(() => {
    setActive(false);
    setSearch(selectedItem?.title || '');
    searchInputRef.current?.blur();
  }, [items, selectedItem]);

  const selectItem = (item: T | null) => {
    const value = item?.id || null;
    setActive(false);
    setSearch(item?.title || '');
    searchInputRef.current?.blur();

    if (value !== selected) {
      onChange?.(item || null);

      if (name) {
        router.get('', {
          ...payload(item?.id || null) || {},
          [name]: item?.id,
        }, { preserveScroll: true, preserveState: true, replace: true });
      }
    }
  };

  useEffect(() => {
    setSearch(selectedItem?.title || '');
  }, [selectedItem]);

  const [focusIndex, setFocusIndex, listRef] = useFocusIndex({
    initialIndex: initialIndex,
    maxIndex: filteredItems.length - 1,
    active,
    onSelect: (index) => {
      if (index > -1) {
        selectItem(filteredItems[index]);
      } else {
        closePicker();
      }
    },
    onCancel: () => {
      closePicker();
    },
  });

  useOnClickOutside(containerRef, () => {
    if (active) {
      closePicker();
    }
  });

  const id = props.id || `${name || 'picker'}_${Array.from(Array(6), () => Math.floor(Math.random() * 36).toString(36)).join('')}`;

  const dropdownHeightClass = {
    sm: 'max-h-[296px]',
    md: 'max-h-[203px]',
    lg: 'max-h-[210px]',
  }[props.inputSize || 'md'];

  return (
    <div className={classNames('relative hover:z-30 ', active && 'z-40')} ref={containerRef}>
      <InputWrapper className={classNames(!disabled && 'hover:[&>input:not(:focus)]:border-slate-300')}>
        <label htmlFor={id}>
          <Icon className={disabled ? 'text-slate-300' : (search ? 'text-slate-800' : 'text-slate-400')}>
            {icon || <Filter />}
          </Icon>
        </label>
        <Input
          {...props}
          value={search}
          onChange={handleSearch}
          onFocus={openPicker}
          placeholder={selectedItem?.title || placeholder}
          ref={searchInputRef}
          className={classNames(
            'pl-9 transition-colors',
            !active && !disabled && 'cursor-pointer',
            optional && 'pr-10',
            className,
          )}
          id={id}
          disabled={disabled}
          autoComplete="off"
        />
        {(optional && selected) ? (
          <>
            <div
              className="absolute block w-8 bg-gradient-to-l from-white to-transparent right-10 top-[1px] bottom-[1px] cursor-text"
              onClick={() => searchInputRef.current?.focus()}
            />
            <Button
              onClick={(event) => {
                selectItem(null);
                event.currentTarget.blur();
              }}
              variant="tertiary"
              className="relative text-slate-400 hover:text-slate-800 !border-transparent"
              disabled={disabled}
              aria-label={t('shared:filter.clear')}
              size={props.inputSize}
            >
              <Icon>
                <XCircle />
              </Icon>
            </Button>
          </>
        ) : (
          <>
            <div
              className={classNames(
                'absolute flex items-center justify-center w-10 right-[1px] top-[1px] bottom-[1px] text-slate-400',
                !disabled && 'cursor-pointer'
              )}
              onClick={() => searchInputRef.current?.focus()}
            >
              <Icon>
                <ChevronDown />
              </Icon>
            </div>
          </>
        )}
      </InputWrapper>
      {active && (
        <FadeIn className={classNames(
          'absolute left-0 right-0',
          props.inputSize === 'sm' && 'top-8',
          (!props.inputSize || props.inputSize === 'md') && 'top-10',
          props.inputSize === 'lg' && 'top-12',
        )}>
          <div className={classNames(dropdownHeightClass, 'p-0.5 bg-white border rounded-md shadow-xs overflow-y-auto space-y-[1px]')} ref={listRef} id={`${id}_list`}>
            {filteredItems.map((item, index) => (
              <button
                type="button"
                onClick={() => selectItem(item)}
                className={classNames(
                  'block w-full px-2 text-left rounded-sm',
                  props.inputSize == 'lg' ? 'py-2' : 'py-1',
                  index === focusIndex ? 'bg-blue-600 text-white' : 'text-slate-800 hover:bg-slate-100',
                )}
                id={`${id}_list_${index}`}
                key={item.id}
              >
                {item.title}
              </button>
            ))}
            {filteredItems.length === 0 && (
              <div className="py-4 text-center text-slate-400">
                {t('shared:picker.no_results')}
              </div>
            )}
          </div>
        </FadeIn>
      )}
    </div>
  );
}

import classNames from 'classnames';
import { nl } from 'date-fns/locale/nl';
import { FocusEvent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Calendar, ChevronLeft, ChevronRight, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { dateFormats, useLocale } from '../locale';
import { Button } from './Button';
import { Icon } from './Icon';
import { Input } from './Input';
import { InputWrapper } from './InputWrapper';

registerLocale('nl', nl);

interface DateInputProps {
  value?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  minDate?: string | null;
  onChange?: (value: string | null) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  optional?: boolean;
  id?: string;
  ariaInvalid?: boolean;
}

export function DateInput({
  value,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
  minDate,
  onChange,
  onBlur,
  disabled,
  optional,
  id,
  ariaInvalid,
}: DateInputProps) {
  const { t } = useTranslation();
  const { locale, formatDate, parseDate } = useLocale();

  const handleChange = (value: Date | null) => {
    onChange?.(value !== null ? formatDate(value, 'internal_date') : null);
  };

  const selected = value ? parseDate(value, 'internal_date') : null;

  return (
    <InputWrapper>
      <label htmlFor={id}>
        <Icon className={classNames(value ? 'text-slate-800' : 'text-slate-400')}>
          <Calendar />
        </Icon>
      </label>
      <DatePicker
        selected={selected}
        onChange={handleChange}
        onBlur={onBlur}
        customInput={<Input className="pl-9" />}
        locale={locale}
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        minDate={minDate ? new Date(minDate) : undefined}
        dateFormat={dateFormats[locale].input_date}
        autoComplete="off"
        placeholderText={t('shared:date_input.date_format_placeholder')}
        id={id}
        disabled={disabled}
        ariaInvalid={ariaInvalid ? 'true' : undefined}
        shouldCloseOnSelect
        showTimeSelect={false}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between">
            <Button
              onClick={decreaseMonth}
              size="sm"
              variant="secondary"
              disabled={prevMonthButtonDisabled}
            >
              <Icon>
                <ChevronLeft />
              </Icon>
            </Button>

            <span className="font-medium">
              {formatDate(date, 'display_month')}
            </span>

            <Button
              onClick={increaseMonth}
              size="sm"
              variant="secondary"
              disabled={nextMonthButtonDisabled}
            >
              <Icon>
                <ChevronRight />
              </Icon>
            </Button>
          </div>
        )}
      />
      {optional && value && !disabled && (
        <Button
          onClick={() => onChange?.(null)}
          variant="tertiary"
          className="relative text-slate-400 hover:text-slate-800 !border-transparent"
          aria-label={t('shared:filter.clear')}
        >
          <Icon>
            <XCircle />
          </Icon>
        </Button>
      )}
    </InputWrapper>
  );
}

import { FocusEvent } from 'react';

import { useLocale } from './locale';

export function useDateInput() {
  const locale = useLocale();

  /**
   * Nicely formats the input value.
   */
  const handleDateBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      let newValue = event.target.value;

      // Remove all characters except 0-9, / and -
      newValue = newValue.replace(/[^0-9/-]/g, '');

      if (locale.locale === 'en') {
        // Accept dashes instead of slashes
        newValue = newValue.replace(/-/g, '/');
      } else if (locale.locale === 'nl') {
        // Accept slashes instead of dashes
        newValue = newValue.replace(/\//g, '-');
      }

      const parsed = locale.parseDate(newValue, 'input_date_short')
        || locale.parseDate(newValue, 'input_date');

      event.target.value = parsed ? locale.formatDate(parsed, 'input_date') : '';
    }
  };

  return {
    handleDateBlur,
  };
}

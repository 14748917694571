import classNames from 'classnames';
import { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

export const Textarea = forwardRef<HTMLTextAreaElement, DetailedHTMLProps<TextareaAutosizeProps & TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>>(
  (props, ref) => {
    const className = classNames(
      'block w-full text-slate-900 placeholder:text-slate-400 rounded-md border border-slate-250 shadow-2xs focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 py-2 px-3 text-base',
      props.className,
    );

    return (
      <TextareaAutosize minRows={3} {...props} ref={ref} className={className} />
    );
  }
);

Textarea.displayName = 'Textarea';

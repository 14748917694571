import { useCallback } from 'react';
import { scroller } from 'react-scroll';

interface ScrollToOptions {
  offset?: number;
  gutter?: number;
  ignoreNavBar?: boolean;
  scrollContainer?: string;
  duration?: number;
}

const useScroll = () => {
  const scrollTo = (name: string, options: ScrollToOptions = {}) => {
    if (document.querySelector(`#${name}`)) {
      scroller.scrollTo(name, {
        duration: options.duration || ((scrollDistance: number) => Math.min(Math.abs(scrollDistance) / 4, 250)),
        containerId: options.scrollContainer,
        offset: options.offset,
        smooth: true,
        delay: 0,
      });
    }
  };

  const scrollToTop = (options: ScrollToOptions = {}) => {
    scrollTo('app', {
      ignoreNavBar: true,
      ...options,
    });
  };

  const scrollToIfInvisible = useCallback((name: string, options: ScrollToOptions = {}) => {
    const element = document.querySelector(`#${name}`);

    if (element && !isInViewport(element)) {
      scrollTo(name, options);
    }
  }, [scrollTo]);

  return {
    scrollTo,
    scrollToTop,
    scrollToIfInvisible,
  };
};

/**
 * @param {number} portion The fraction maximum of the element that may be visible in the viewport
 */
const isInViewport = (element: Element, portion = 0) => {
  const { top } = element.getBoundingClientRect();
  const height = Math.min(element.clientHeight, window.innerHeight);
  return (top - portion * height) >= 0 && (top + portion * height) <= window.innerHeight;
};

export default useScroll;

import classNames from 'classnames';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { Button } from '../UI/Button';
import style from './Modal.module.css';

interface ModalProps extends ReactModal.Props {
  size?: 'sm' | 'md' | 'lg';
  onClose?: () => void;
}

export function Modal({
  isOpen = false,
  size = 'md',
  onClose,
  children,
}: ModalProps) {
  const { t } = useTranslation();

  const className = classNames(
    'bg-white rounded-xl shadow-lg drop-shadow-lg p-6 sm:p-8 max-w-3xl mx-auto relative',
    size === 'sm' && 'max-w-lg',
    size === 'md' && 'max-w-3xl',
    size === 'lg' && 'max-w-6xl',
    style['modal-content'],
  );

  return (
    <ReactModal
      isOpen={isOpen}
      className={className}
      overlayClassName={`fixed bg-slate-800/40 z-50 inset-0 px-4 py-12 sm:p-12 lg:p-24 ${style['modal-overlay']}`}
      appElement={document.getElementById('app') || undefined}
    >
      {onClose && (
        <Button
          onClick={onClose}
          variant="tertiary"
          size="sm"
          className="absolute top-8 right-8 text-slate-400 hover:text-slate-700 transition !p-0.5"
          aria-label={t('shared:modal.close')}
        >
          <X strokeWidth={1.5} className="block w-7 h-7" />
        </Button>
      )}
      {children}
    </ReactModal>
  );
}

import classNames from 'classnames';
import { DetailedHTMLProps, LabelHTMLAttributes } from 'react';

interface RadioLabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  disabled?: boolean;
}

export default function RadioLabel({
  className,
  disabled,
  children,
  ...props
}: RadioLabelProps) {
  return (
    <label
      className={classNames(
        disabled ? 'text-slate-400' : 'cursor-pointer',
        '[&_input]:mt-1 flex space-x-2',
        className,
      )}
      {...props}
    >
      {children}
    </label>
  );
}
